<template>
<div class="container">
	<h1 class="title">Términos y Condiciones para prestadores de servicios (PROVEEDORES).</h1>

    <p class="paragraph">
        GO GUAU <br>
        !Tu mejor amigo en manos amigas¡
    </p>
    <p class="paragraph">
        Última actualización: 26 de Mayo de 2021
    </p>
   
    <div>
        <p class="subtitle">I. RELACIÓN CONTRACTUAL </p>
        <p class="paragraph">Las presentes Condiciones (“Condiciones”) regulan el acceso o uso que usted haga, 
            como persona (incluso sin una cuenta), desde cualquier país del mundo de las aplicaciones 
            tecnológicas, páginas web, contenido, dependencias, medios de comunicación, productos y 
            servicios puestos a su disposición por la empresa GO GUAU, para la consecución, programación 
            y cobro de los servicios para mascotas que usted como PROVEEDOR presta, los cuales llamaremos 
            de ahora en adelante y en general “LA PLATAFORMA”; GO GUAU es una sociedad de responsabilidad 
            limitada constituida en Popayán Cauca, en la cámara de comercio de la misma ciudad.
        </p>
        <p class="paragraph">
            LEA ESTAS CONDICIONES DETENIDAMENTE ANTES DE ACCEDER, USAR O SOLICITAR LOS SERVICIOS DE GO GUAU.
        </p>            
        <p class="paragraph">
          Mediante su acceso, uso o consecución de servicios mediante la plataforma de GO GUAU, 
          usted acuerda vincularse jurídicamente por estas Condiciones, que establecen una relación 
          contractual entre usted y GO GUAU. Si usted no acepta estas Condiciones, no podrá acceder 
          o usar LA PLATAFORMA. Estas Condiciones sustituyen expresamente los acuerdos o compromisos 
          previos que se hayan tenido con usted. GO GUAU podrá poner fin de inmediato a estas Condiciones 
          o cualquiera de los Servicios o productos brindados a usted o, en general, dejar de ofrecer o 
          denegar el acceso a LA PLATAFORMA o cualquier parte de ella, en cualquier momento y por 
          cualquier motivo a su criterio.
        </p>
    </div>

    <div>
        <p class="subtitle">II. TERMINOS Y CONDICIONES PRINCIPALES </p>
        <p class="subtitle2">1. ALCANCE DEL SERVICIO</p>
        <p class="paragraph">
            El servicio ofrecido por GOGUAU se constituye en el uso de su PLATAFORMA por parte de usuarios 
            que prestan servicios y proveen productos para mascotas que en general llamaremos PROVEEDORES, 
            en este caso usted, y por otra parte personas que requieren servicios para mascotas que acordamos 
            llamar CLIENTES. GO GUAU tiene por propósito sin obligarse a ello, FACILITAR LA INTERMEDIACIÓN 
            entre CLIENTES Y PROVEEDORES, por ello podrá denominarse también el INTERMEDIARIO. El uso de 
            la plataforma de GO GUAU podrá permitir organizar y planear los servicios de paseos y 
            adiestramiento entre las partes, así como la obtención de productos.
        </p>
        <p class="paragraph">
            Con el propósito de la consecución de clientes usted autoriza a GO GUAU la promoción de sus 
            servicios como paseador y/o adiestrador canino, cuyas características de servicio son 
            responsabilidad suya exclusivamente como PROVEEDOR y las cuales usted se obliga a 
            cumplir de la siguiente forma:
        </p>
        
        
        <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Servicio de paseo de mascotas</span>
        </p> 

        <CRow class="paragraph">
            <CCol col="12" xl="10">
            <CCard>                
                <CCardBody>
                    <b-table
                        striped
                        :items="itemsWalkingFeats"
                        :fields="fieldsWalkingFeats"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        stacked="md"
                        show-empty
                        small
                        @filtered="onFiltered"      
                        >
                        <template #cell(name)="row">
                            {{ row.value }}                        
                        </template>                                             
                    </b-table>
                </CCardBody>
            </CCard>
            </CCol>
        </CRow>
    
        <p class="paragraph padded">
            <span class="bullet">●</span><span class="margin">Servicio de adiestramiento</span>
        </p> 

        <CRow class="paragraph">
            <CCol col="12" xl="10">
            <CCard>                
                <CCardBody>
                    <b-table
                        striped
                        :items="itemsTrainingFeats"
                        :fields="fieldsTrainingFeats"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        stacked="md"
                        show-empty
                        small
                        @filtered="onFiltered"      
                        >
                        <template #cell(name)="row">
                            {{ row.value }}                        
                        </template>                                             
                    </b-table>
                </CCardBody>
            </CCard>
            </CCol>
        </CRow>       

        <p class="subtitle2">2. TIEMPOS Y COSTOS</p>    

            <p class="paragraph">
                Usted como PROVEEDOR autoriza al INTERMEDIRARIO a fijar a su criterio una tarifa total ante el CLIENTE y 
                a actuar ante el mismo como agente de cobro limitado; Usted reconoce y acepta que el
                monto cobrado a su nombre estipula EL <strong>costo del servicio prestado por usted y el costo del servicio de 
                intermediación que adeuda usted a GO GUAU</strong>, en ese orden de ideas, usted acepta que:
            </p>               
            <p class="paragraph padded">                
                1. El INTERMEDIARIO desembolse a usted como PROVEEDOR, las suma económica 
                    correspondiente al <strong>costo del servicio prestado por usted</strong>, o lo que es lo mismo, el pago del 
                    CLIENTE por sus servicios, de acuerdo a la siguiente tabla:                                                                 
            </p>           

            <CRow>
                <CCol col="12" xl="10">
                    <CCard>                
                        <CCardBody>
                        <CDataTable
                            hover
                            striped
                            :items="itemsWalkerFees"
                            :fields="fieldsWalkerFees"
                            :items-per-page="20"                    
                            :active-page="activePage"                    
                            :pagination="{ doubleArrows: false, align: 'center'}"                    
                        >
                            <template #concept="data">
                            <td>
                                <CBadge :color="getBadge(data.item.concept)">
                                {{data.item.concept}}
                                </CBadge>
                            </td>
                            </template>
                        </CDataTable>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>  

            <p class="paragraph padded">
                Usted acepta que el desembolso aquí estipulado solo se hará luego de recaudo ante el 
                CLIENTE y que el INTERMEDIARIO puede hacer un único desembolso cada 15 días, cuya demora 
                o retraso no incurrirá en intereses o multas. Las omisiones del cliente no serán 
                imputables a GO GUAU.
            </p>  

            <p class="paragraph padded">2. Respecto del excedente económico luego del desembolso del punto uno, 
                usted autoriza que este sea el pago a GO GUAU por los costos del servicio de intermediación, en 
                consecuencia usted renuncia a cualquier derecho sobre ese recurso.</p>
            

            <p class="paragraph"><strong>Notas</strong></p>

            <p class="paragraph">
                <span class="bullet">●</span><span class="margin">
                    Un GO GUAU AMIGO hace referencia a una mascota.
                </span>                                
            </p>
            <p class="paragraph">                
                <span class="bullet">
                    ●
                </span>
                <span class="margin">En los adiestramientos, el CLIENTE debe suministrar 
                    los premios y/o recompensa (galletas, gomitas).
                </span>
            </p>

        <div>
            <p class="subtitle2">3. MEDIOS DE TRANSACCIÓN</p>
            <p class="paragraph">Los medios dispuestos para el re direccionamiento de los fondos correspondientes 
                a sus servicios prestados como PROVEEDOR podrán ser:</p>
            <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
            Transferencia bancaria Bancolombia</span></p>
            <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
            Transferencia Nequi</span></p>
            <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
            Daviplata.</span></p>
            <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
            Efectivo: a quien designe GO GUAU previa autorización</span></p>       

            <p class="paragraph">
                Usted se obliga a suministrar los datos de cuenta según corresponda al correo electrónico
                contacto@goguau.co
            </p>    
        </div>

        <div>   
            <p class="subtitle2">4. TÉRMINOS DE ESPERA, CANCELACIONES Y DEVOLUCIÓN DE DINERO</p>
            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong>Esperas:</strong> </span> <br><br>
                <span>Al momento de las prestaciones del servicio existe la posibilidad de que el CLIENTE no 
                    atienda al llamado cuando Usted como PROVEEDOR (PASEADOR O ADIESTRADOR) realice presencia 
                    en el domicilio y se pueden dar las siguientes situaciones:</span> <br><br>

                <span>a. Ninguna respuesta al llamado a puerta: en esta situación el paseador (PROVEEDOR) 
                    realizará 3 llamados a puerta y esperará un máximo de 20 minutos contados desde la hora 
                    en que debería iniciar el servicio, de no haber ninguna respuesta, el paseador (PROVEEDOR) 
                    se retirará del domicilio del cliente y el servicio se entenderá como ejecutado.</span> <br><br>
                <span>b. Respuesta tardía al llamado a puerta: en la situación en las que el CLIENTE atienda 
                    el llamado del paseador (PROVEEDOR) para iniciar el servicio de forma tardía y durante el 
                    plazo máximo de 20 minutos, el PROVEEDOR ejecutará el servicio Manteniendo la hora de 
                    finalización programada para no afectar la agenda de servicios posteriores.</span> <br><br>

                <span>Llegada tardía del PROVEEDOR, en caso de que el PROVEEDOR no llegue a la hora acordada 
                    para el servicio, el usuario podrá informar a la línea GO GUAU 3194115792 o por otros medios, 
                    dicha situación, de esta forma se solicitará compensación del tiempo la cual usted se 
                    compromete a ejecutar durante el mismo servicio o en servicios posteriores según lo 
                    permita el agendamiento. Usted como PROVEEDOR reconoce y acepta el 
                    <strong>MANUAL DEL PASEADOR Y/O MANUAL DE ADIESTRADOR</strong> según corresponda 
                    como parte de este contrato, se obliga a leerlo y entenderlo, así 
                    mismo se obliga a cumplir con las estipulaciones de multas por retrasos o incumplimientos 
                    ahí contempladas.</span>
                    
            </p>

            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong>Cancelación de servicios programados por motivos diferentes a lluvia:</strong> </span> <br><br>
                <span>Respecto de los servicios programados, el cliente podrá realizar el siguiente número de
                    cancelaciones con reprogramación sin costo según el paquete de compra:</span> <br><br>
                
                <CRow class="paragraph">
                    <CCol col="12" xl="10">
                    <CCard>                
                        <CCardBody>
                            <b-table
                                striped
                                :items="itemsReschedule"
                                :fields="fieldsReschedule"
                                :current-page="currentPage"
                                :per-page="perPage"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :sort-direction="sortDirection"
                                stacked="md"
                                show-empty
                                small
                                @filtered="onFiltered"      
                                >
                                <template #cell(name)="row">
                                    {{ row.value }}                        
                                </template>                                             
                            </b-table>
                        </CCardBody>
                    </CCard>
                    </CCol>
                </CRow>                
            </p>

                <p class="paragraph padded">
                    Lo anterior, sin que sea necesario una justificación o motivo, siempre y cuando la solicitud 
                    de <strong>cancelación se informe debidamente con un tiempo superior a 24 horas anticipadas</strong> a la 
                    prestación del servicio y haya sido aprobada por GO GUAU en el mismo lapso de tiempo. Es 
                    responsabilidad del CLIENTE la solicitud de reprogramación, cuya ejecución solo podrá llevarse 
                    a cabo entre la fecha del paseo inicial y final, estipuladas al momento del pago y quedará 
                    sujeta a disponibilidad de paseadores.
                </p>

                <p class="paragraph padded">
                    <strong>En caso de que el CLIENTE realice cancelaciones durante las 24 horas anteriores 
                    a la prestación del servicio, con justificación o sin ella</strong>, de la tarifa de cancelación 
                    estipulada pro GO GUAU usted recibirá 1500 pesos.
                </p>

            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong>Cancelación por lluvia de servicios programados:</strong> </span> <br><br>
                <span>
                    En caso de lluvia prevista <strong> el CLIENTE podrá realizar la cancelación y reprogramación del servicio, 
                    siempre y cuando dicha solicitud se informe debidamente con más de 30 minutos de anticipación</strong> a la 
                    prestación del servicio y haya sido aprobada por GO GUAU en el mismo lapso de tiempo. El Nro. de 
                    cancelaciones con reprogramación por lluvia permitidas es el siguiente:
                </span>
                <br> <br>
                <CRow class="paragraph">
                    <CCol col="12" xl="10">
                    <CCard>                
                        <CCardBody>
                            <b-table
                                striped
                                :items="itemsRainReschedule"
                                :fields="fieldsReschedule"
                                :current-page="currentPage"
                                :per-page="perPage"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :sort-direction="sortDirection"
                                stacked="md"
                                show-empty
                                small
                                @filtered="onFiltered"      
                                >
                                <template #cell(name)="row">
                                    {{ row.value }}                        
                                </template>                                             
                            </b-table>
                        </CCardBody>
                    </CCard>
                    </CCol>
                </CRow>                 
                
                <span>Es responsabilidad del CLIENTE la solicitud de reprogramación, cuya ejecución solo podrá llevarse a cabo entre la fecha del paseo inicial y final, estipuladas al momento del pago del paquete y quedará sujeta a disponibilidad de paseadores, las reprogramaciones no son acumulativas de un paquete a otro.</span> <br><br>
                <span><strong>Para cancelaciones de servicios en tiempos menores a 30 minutos de su ejecución</strong>, de la tarifa de cancelación estipulada por GO GUAU usted recibirá 1500 pesos.</span> <br><br>
                <span>En el caso de que durante la ejecución del servicio EL CLIENTE decida suspender la ejecución del mismo, no habrá lugar a compensación del tiempo, no habrá cobro de tarifa de cancelación, pero el paseo se entenderá como ejecutado.</span> <br><br>                                                
                <span>Considerando que la lluvia es un factor externo, de fuerza mayor y teniendo en cuenta también el tiempo cesante de los paseadores junto a las limitaciones de agenda, una vez consumidos los créditos de cancelación con reprogramación, a todas cancelaciones adicionales aplicara la tarifa de cancelación.</span> <br><br>                                                
                <span><strong>Tenga en cuenta que GO GUAU como plataforma de intermediación y usted como PROVEEDOR ejecutor de la actividad de paseo o adiestramiento no realizan cancelaciones de servicios</strong>, esta es una solicitud inherente al cliente y deberá hacerse única y exclusivamente a través de la línea {{line}} y no al PROVEEDOR.</span> <br><br>                                                
                
            </p>
        </div>

        <div>
            <p class="subtitle2">5. RESPONSABILIDAD SOCIAL</p>
            <p class="paragraph">En ánimo de aportar socialmente, GO GUAU facilitará sin obligarse a ello, a que los proveedores de servicios independientes (PROVEEDORES), tengan el perfil de estudiantes activos, para los cuales los ingresos que puedan resultar de su labor ante los CLIENTES representen una oportunidad para mantener sus actividades académicas o sustente sus necesidades básicas.</p>
        </div>
        
    </div>
    <br>
    <div>
        <p class="subtitle">III. TERMINOS Y CONDICIONES COMPLEMENTARIOS. </p>
        <br>
        <div>
            <p class="subtitle2">1. CONDICIONES SUPLEMENTARIAS</p>
            <p class="paragraph">Usted acuerda que GO GUAU podrá aplicar condiciones suplementarias a los Servicios, como políticas para un evento, una actividad o una promoción particular, y que dichas condiciones suplementarias se le comunicarán mediante la plataforma. Las condiciones suplementarias se establecen como adicionales a estas Condiciones, y se considerarán una parte de las mismas. Las condiciones suplementarias prevalecerán sobre estas Condiciones aquí estipuladas en el caso de conflicto respecto a los Servicios a que apliquen.</p>
            <p class="paragraph">
                GO GUAU podrá modificar las presentes Condiciones cuando lo considere oportuno. Las modificaciones serán efectivas después de la publicación de las mismas en la siguiente ubicación de internet: <a href="https://goguau.co/pages/termswalker">goguau.co/pages/termswalker</a> . Su acceso o uso de los Servicios después de dicha publicación constituye su consentimiento a vincularse por las Condiciones ahí estipuladas y sus modificaciones.
            </p>                                      
        </div>

        <div>
            <p class="subtitle2">2. TERMINOS Y CONDICIONES ADICIONALES A LOS SERVICIOS</p>
            <p class="paragraph">
                A no ser que GO GUAU lo acepte mediante un contrato por aparte y por escrito con usted, los Servicios se ponen a disposición solo para uso personal, no comercial. Usted reconoce que GO GUAU no presta servicios de paseos y adiestramiento, ni venta de productos y que estos son prestados por usted como PROVEEDOR AL CLIENTE, reconoce también que usted no está empleado por GO GUAU ni por ninguna de sus filiales o representantes, también <strong>reconoce y acepta que Go GUAU es una empresa de tecnología, que facilita la comunicación y/o intermediación entre CLIENTES Y PROVEEDORES de servicios y productos de mascotas, sin obligarse a ello.</strong>
            </p>                         

            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong>Licencia:</strong> </span><br><br>
                <span>Sujeto al cumplimiento de estas Condiciones, GO GUAU le otorga una licencia limitada, no exclusiva, no sublicenciable, revocable, no transferible para: (i) el acceso y uso de la PLATAFORMA, mediante su dispositivo personal u ordenador u otro; y (ii) el acceso y uso de cualquier contenido, información y material relacionado que pueda ponerse a disposición, en cada caso solo para su uso personal, no comercial. GO GUAU y sus licenciantes se reservan cualquier derecho que no haya sido expresamente otorgado por el presente documento.</span><br><br>

                <span class="bullet">●</span><span class="margin"><strong>Restricciones:</strong> </span><br><br>
                <span>Usted no podrá: (i) retirar cualquier nota de derechos de autor, marca registrada u otra nota de propiedad de cualquier contenido de GO GUAU; (ii) reproducir, modificar, preparar obras derivadas sobre los Servicios, distribuir, licenciar, arrendar, revender, transferir, exhibir, presentar públicamente, transmitir, retransmitir o explotar de otra forma contenido, servicios y/o herramientas de GO GUAU , excepto como se permita expresamente por GO GUAU; (iii) descompilar, realizar ingeniería inversa o desmontar LA PLATAFORMA, excepto como se permita por la ley aplicable; (iv) enlazar, reflejar o enmarcar cualquier parte de los Servicios; (v) causar o lanzar cualquier programa o script con el objeto de extraer, indexar, analizar o de otro modo realizar prospección de datos de cualquier parte de LA PLATAFORMA o sobrecargar o bloquear indebidamente la operación y/o funcionalidad de cualquier aspecto; o (vi) intentar obtener un acceso no autorizado o dañar cualquier parte de los Servicios o sus sistemas o redes.</span><br><br>
                
                <span class="bullet">●</span><span class="margin"><strong>Prestación de los Servicios:</strong> </span><br><br>
                <span>Usted reconoce y acepta que algunos Servicios o productos podrán ponerse a disposición de los CLIENTES bajo varias marcas u opciones de pedidos asociadas como: (i) ciertas subsidiarias o afiliados de GO GUAU; o (ii) Terceros proveedores independientes, incluidos paseadores caninos, adiestradores, veterinarias y otros.</span><br><br>
                                                              
                <span class="bullet">●</span><span class="margin"><strong>Titularidad:</strong> </span><br><br>
                <span>Los Servicios de uso de la plataforma y todos los derechos relativos a estos son y permanecerán como propiedad de GO GUAU o de sus licenciantes. Ninguna de estas Condiciones ni el uso de los Servicios le transfieren u otorgan ningún derecho: (i) sobre o en relación con los Servicios, excepto en cuanto a la licencia limitada otorgada anteriormente.</span><br><br>
            </p> 
        </div>

        <div>
            <p class="subtitle2">3. USO DE LOS SERVICIOS</p>
            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong>Cuentas de usuario:</strong> </span><br><br>
                <span>Con el fin de usar los Servicios, usted debe registrarse en <a href="www.goguau.co">goguau.co</a> y mantener activa una cuenta personal de usuario (“Cuenta”). Para obtener una debe tener como mínimo 18 años. El registro de la cuenta requiere que comunique a GO GUAU determinada información personal, como su nombre, dirección, número de teléfono móvil, número de identificación así como por lo menos un método de transacción válido. Usted se compromete a mantener la información de su Cuenta precisa, completa y actualizada, de no hacerlo o incluso el hecho de tener un método de transacción inválido o que haya vencido, podrá resultar en su imposibilidad para acceder y utilizar la PLATAFORMA. Usted es responsable de toda la actividad que ocurra en su Cuenta y se compromete a mantener en todo momento de forma segura y secreta el nombre de usuario y la contraseña. A menos que GO GUAU permita otra cosa por escrito, usted solo puede poseer una Cuenta.</span><br><br>                
                
                <span class="bullet">●</span><span class="margin"><strong>Consentimiento para llamadas mensajes de texto, correos y otras comunicaciones:</strong> </span><br><br>
                <span>Al usar la PLATAFORMA de GO GUAU, Usted da su consentimiento expreso para recibir y aceptar comunicaciones nuestras, o de nuestros Afiliados (como se define a continuación) y sus respectivos representantes, por correo electrónico, llamadas telefónicas, mensajes de texto (incluso mediante un sistema de marcación telefónica automática o una voz pregrabada), notificaciones push u otros medios, en cualquiera de las direcciones de correo electrónico y / o números de teléfono proporcionados por usted o en su nombre a GO GUAU. Usted acepta que las comunicaciones autorizadas anteriores pueden iniciarse para cualquier transacción, servicio al cliente, publicidad, marketing, promoción, cobro de deudas, administración de cuentas u otros fines.</span><br><br>
                
                <span class="bullet">●</span><span class="margin"><strong>Contenido proporcionado por el Usuario:</strong> </span><br><br>
                <span>GO GUAU podrá, a su sola discreción cuando considere oportuno, permitirle que envíe, cargue, o publique, información de texto, audio y/o visual, incluidos comentarios y opiniones relativos a los Servicios, acepta usted entonces que todo Contenido de usuario facilitado o autorizado por usted seguirá siendo de su propiedad. No obstante, al proporcionar o autorizar dicho Contenido a GO GUAU, usted otorga una licencia mundial, perpetua, irrevocable, transferible, libre de regalías, con derecho a sublicenciar, usar, copiar, modificar, crear obras derivadas, distribuir, exhibir públicamente, presentar públicamente o de otro modo explotar de cualquier manera dicho Contenido de usuario en todos los formatos y canales de distribución, conocidos ahora o ideados en un futuro (incluidos en relación con los Servicios y el negocio de GO GUAU y en sitios y servicios de terceros), sin aviso o consentimiento suyo y sin requerirse el pago a usted o a cualquier otra persona o entidad. Usted declara y garantiza que: (i) es el único y exclusivo propietario de todo el Contenido de usuario facilitado o autorizado y que tiene todos los derechos, licencias, consentimientos y permisos necesarios para otorgar a GO GUAU la licencia del Contenido como se ha establecido anteriormente; y (ii) ni el Contenido de usuario, ni su presentación, carga, publicación o puesta a disposición, ni el uso por parte de GO GUAU del Contenido de usuario como está aquí permitido, infringirán, malversarán o violarán la propiedad intelectual o los derechos de propiedad de un tercero o resultarán en la violación de cualquier ley o reglamento aplicable.</span><br><br>
                <span>Usted acuerda no proporcionar Contenido de usuario que sea difamatorio, calumnioso, odioso, violento, obsceno, pornográfico, ilícito o de otro modo ofensivo; GO GUAU, a su sola discreción y en cualquier momento y por cualquier motivo, sin avisarle previamente, podrá revisar, controlar o eliminar Contenido de usuario.</span><br><br>
                <span>Los CLIENTES aceptan la recolección suya como PROVEEDOR del material fotográfico y fílmico de las mascotas al momento de la prestación de los servicios, por ello usted se responsabiliza de entregar dicho material a GO GUAU, según se le demande.</span><br><br>
                
                
                <span class="bullet">●</span><span class="margin"><strong>Acceso a la red y dispositivos:</strong> </span><br><br>
                <span>
                    Usted es responsable de obtener el acceso a la red de datos necesario para utilizar los Servicios de GO GUAU y para garantizar la oferta hecha a los CLIENTES. Podrán aplicarse las tarifas y tasas de datos y mensajes de su red móvil si usted accede o utiliza los Servicios desde un dispositivo inalámbrico y usted será responsable de dichas tarifas y tasas. Usted es responsable de adquirir y actualizar el hardware compatible o los dispositivos necesarios para acceder y utilizar los Servicios y Aplicaciones del INTERMEDIARIO y cualquier actualización de estos. GO GUAU no garantiza que los Servicios, o cualquier parte de estos, funcionen en cualquier hardware o dispositivo particular. Además, los Servicios podrán ser objeto de disfunciones o retrasos inherentes al uso de Internet y de las comunicaciones electrónicas.    
                </span><br><br>                                
                
            </p>
        </div>

        <div>
            <p class="subtitle2">4. PAGO</p>
            <p class="paragraph">Usted entiende que el uso de los Servicios de la PLATAFORMA de GO GUAU para la <strong>INTERMEDIACIÓN</strong> derivan en cargos (“Cargos”) a favor del mismo que hemos resuelto llamar también <strong>INTERMEDIARIO</strong>, como se manifestó anteriormente el costo variable por los servicios de GO GUAU corresponderán al EXCEDENTE de la tarifa cobrada al CLIENTE, luego del desembolso a usted por concepto del servicio prestado o bien suministrado, según sea el caso. Los pagos a GO GUAU son definitivos y no reembolsables, a menos que GO GUAU determine lo contrario. Todos los pagos a GO GUAU serán inmediatos al recaudo frente al CLIENTE.</p>                                            
        </div>

        <div>
            <p class="subtitle2">5. RENUNCIAS, LIMITACIÓN DE RESPONSABILIDAD E INDEMNIDAD DE GO GUAU</p>

            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong>Renuncia:</strong> </span><br><br>
                <span>Los servicios se proporcionan “tal cual” y “como disponibles”. GO GUAU renuncia a toda declaración y garantía, expresa, implícita o estatutaria, no expresamente establecida en estas condiciones, incluidas las garantías implícitas de comerciabilidad, idoneidad para un fin particular y no violatorio. además, GO GUAU no hace declaración ni presta garantía alguna relativa a la fiabilidad, puntualidad, calidad, idoneidad o disponibilidad de los servicios o bienes solicitados a través del uso de la plataforma ó de la plataforma misma, o que los servicios no serán interrumpidos o estarán libres de errores.</span><br><br>   
                <span>Usted acuerda que todo riesgo derivado de su prestación de servicio al CLIENTE ó en relación al mismo será únicamente suyo.</span><br><br>   
               
                <span class="bullet">●</span><span class="margin"><strong>Limitación de responsabilidad:</strong> </span><br><br>
                <span>GO GUAU no será responsable de daños indirectos, incidentales, especiales, ejemplares, punitivos o emergentes, incluidos el lucro cesante, la pérdida de datos, la lesión personal o el daño a la propiedad, ni de perjuicios relativos, o en relación con, o de otro modo derivados de cualquier uso de los servicios de la plataforma, tampoco de la prestación de su servicio como PROVEEDOR, incluso aunque GO GUAU haya sido advertido de la posibilidad de dichos daños. GO GUAU no será responsable de cualquier daño, responsabilidad o pérdida que deriven de: (i) su uso o dependencia de los servicios o su incapacidad para acceder o utilizar los servicios de la plataforma; o (ii) cualquier transacción o relación entre usted y cualquier tercero CLIENTE, aunque GO GUAU hubiera sido advertido de la posibilidad de dichos daños. GO GUAU no será responsable del retraso o de la falta de ejecución resultante que vayan más allá del control razonable de GO GUAU. En ningún caso la responsabilidad total de GO GUAU hacia usted en relación con los servicios y por todos los daños que se puedan causar, las pérdidas y los juicios podrá exceder de quinientos mil pesos ($500.000 COP).</span><br><br>   
                <span>Usted podrá utilizar la plataforma de GO GUAU y/o sus dependencias para planificar servicios de PASEADORES Y ADIESTRAMIENTO CANINO, O SUMINISTRO DE BIENES, con los CLIENTES, por ello acepta que GO GUAU no tiene responsabilidad alguna en dichos servicios o productos y que las obligaciones de su objeto se han establecido expresamente en estas condiciones.</span><br><br>   
                
                <span class="bullet">●</span><span class="margin"><strong>Indemnidad:</strong> </span><br><br>
                <span>Usted acuerda mantener indemne a GO GUAU y manifiesta responder frente al mismo, sus consejeros, directores, empleados y agentes por cualquier reclamación, demanda, pérdida, responsabilidad y gasto (incluidos los honorarios de abogados) que deriven de: (i) su uso de la plataforma y/o dependencias, servicios o bienes prestados o vendidos por usted a través de la misma; (ii) su incumplimiento o violación de cualquiera de estas Condiciones; (iii) el uso por parte de GO GUAU de su Contenido de usuario; o (iv) su infracción de los derechos de cualquier tercero, incluidos los CLIENTES del servicio.</span><br><br>   
                
                <span class="bullet">●</span><span class="margin"><strong>Responsabilidad de CLIENTES:</strong> </span><br><br>
                <span>EL CLIENTE y dueño de la mascota, hace un convenio y garantiza a GO GUAU y a cada PROVEEDOR que: (1) las mascotas para las que solicita servicios son suyas; (2) Las mascotas están libres de pulgas, garrapatas y otras afectaciones; (3) ha divulgado cualquier información material sobre las mascotas, incluidas afecciones médicas preexistentes y otros problemas de comportamiento que pueden ser relevantes o afectar la capacidad de un PROVEEDOR para brindar los Servicios; (4) que tendrá las mascotas completamente vacunadas y al día con todas las formas de medicina preventiva antes de recibir los servicios de un PROVEEDOR; (5) cumplirá con todas las leyes y ordenanzas aplicables; y (6) la (s) mascota (s) para las que busca servicios no son peligrosas ni pueden dañar a otras personas, <strong>en caso de duda o si la mascota pertenece a una raza potencialmente peligrosa le pondrá bozal OBLIGATORIAMENTE</strong>.</span><br><br>   
                <span>El cliente es completamente responsable de las acciones de su (s) mascota (s).</span><br><br>   
               
                <span class="bullet">●</span><span class="margin"><strong>Responsabilidad del PROVEEDOR:</strong> </span><br><br>
                <span>No obstante lo anterior, usted como PROVEEDOR acepta y se hace responsable de tomar también todas las medidas de seguridad posibles y de brindar al CLIENTE las indicaciones necesarias en pro de la seguridad suya, de terceros y de la misma mascota, GO GUAU le sugiere principalmente:</span><br><br>   
                <span>1. El uso permanente de bozal.</span><br><br>   
                <span>2. El NO uso de correas retractiles o de ahogamiento</span><br><br>   
                <span>3. El uso de arnés</span><br><br>   
                <span>4. Usted como Proveedor se responsabiliza de mantener siempre con collar y cuerda de control a la mascota, estará siempre bajo su dominio y bajo ninguna consideración la mascota podrá estar suelta.</span><br><br>   
                <span>5. Es responsabilidad suya indagar por el Estado físico y recomendaciones de salud de la mascota.</span><br><br>                                 
                
                <span>Usted como prestador del servicio reconoce y acepta que cualquier evasión a las anteriores recomendaciones es de su exclusiva responsabilidad así como los perjuicios que pueda ello conllevar.</span><br><br>                                 
                <span>Usted comprende, reconoce y acepta que, entre usted Y GO GUAU, usted será responsable de todos y cada uno de los reclamos, costos, procedimientos, demandas, pérdidas, daños y gastos (incluidos, entre otros, los honorarios razonables de abogados y los costos legales de cualquier tipo o naturaleza, que surja o se relacione con la <strong>prestación del servicio de paseo y adiestramiento o la venta de productos</strong>.</span><br><br>
                <span>Usted comprende y acepta que como PROVEEDOR de servicios de mascotas a los CLIENTES y como usuario de la plataforma GO GUAU, es usted una persona independiente y no un socio, empresa conjunta, agente, representante legal, empleador, trabajador o empleado de GO GUAU. Como PROVEEDOR no tienen la capacidad ni la autoridad para representar a GO GUAU o hacer declaraciones o compromisos en su nombre.</span><br><br>                                 
                <span>A manera de contraprestación adicional por el servicio de intermediación, usted acepta y se responsabiliza de llevar la indumentaria que sugiera GO GUAU para la promoción de su marca durante la prestación del servicio.</span><br><br>                                 
                <span>Usted como PROVEEDOR se compromete a ejecutar los servicios mediados por GO GUAU, y quedan a su discreción aquellos servicios que puedan representar peligro o situaciones adversas a usted.</span><br><br>                                 
                <span>En el improbable caso de que una mascota necesite atención médica o veterinaria inmediata en una situación de emergencia, usted está autorizado por el CLIENTE para la provisión de atención veterinaria.</span><br><br>                                 
                <span>Como usuario y PROVEEDOR de servicios de mascotas, usted reconoce que GO GUAU está en el negocio de conectar dueños de mascotas (CLIENTES) y PROVEEDORES de servicios para las mismas, y ese negocio es atreves del cual GO GUAU obtiene sus ingresos. Por ello, en caso de que usted como PROVEEDOR mediante la plataforma de GO GUAU preste servicios a un CLIENTE que conoció previamente por fuera de la actividad de esta empresa, deberá manifestarlo mediante correo electrónico a la dirección contacto@goguau.co para reasignar un nuevo proveedor; en caso de que no realice dicha comunicación, usted como PROVEEDOR acepta que ha conocido por primera vez al CLIENTE gracias al servicio de intermediación de GO GUAU, con base en ello, si usted pactara algún servicio para mascotas con alguno de los CLIENTES que se le facilitó, y dicho pacto se hiciera por fuera de la PLATAFORMA, GO GUAU tendrá el derecho a cobrar tanto al propietario de la mascota (CLIENTE) como a usted PROVEEDOR una tarifa de referencia, Esta tarifa de referencia se cobrará una vez por cada relación de servicios específica entre el CLIENTE y el PROVEEDOR, La tarifa de referencia será de $ 2.000.000 (Dos Millones de Pesos).</span><br><br>                                 
                <span>En cuanto el PROVEEDOR decida no volver a utilizar los servicios de GO GUAU, deberá informar dicha situación al menos con 8 días de anticipación y se obliga a terminar los servicios que ya se pactaron en su nombre y que han sido programados, en especial aquellos con tipificación de exclusividad, so pena de sanción por incumplimiento pactada en 500.000 pesos Col.</span><br><br>                                 
            </p>                                                         
        </div>

        <div>
            <p class="subtitle2">6. LEGISLACIÓN APLICABLE, ARBITRAJE</p>
            <p class="paragraph">Salvo que aquí se especifique lo contrario, las presentes Condiciones se regirán e interpretarán exclusivamente en virtud de la legislación Colombiana. Cualquier disputa, conflicto, reclamación o controversia, del tipo que sea, que resulte de las presentes Condiciones o que se relacione en gran parte con ellas, incluyendo las relativas a su validez, interpretación y exigibilidad (cualquier “Disputa”), deberán someterse forzosamente a procedimientos de mediación en virtud del Reglamento de Mediación de la Cámara de Comercio del Cauca. Si dicha disputa no fuese solucionada en un plazo de sesenta (60) días desde la fecha en la que se formalice la solicitud de mediación en virtud del Reglamento de Mediación de la CCC, se solucionará exclusiva y definitivamente mediante arbitraje en virtud del Reglamento de la Cámara de Comercio del Cauca. Las disposiciones sobre Proceso Expedito y del Árbitro de Emergencia del Reglamento de la CCI no se aplicarán. La disputa será resuelta por un (1) árbitro nombrado para tal fin en virtud del Reglamento de la CCI. El lugar tanto para la mediación como para el arbitraje será Popayán, Colombia. La existencia y el contenido de los procedimientos de mediación y arbitraje, incluidos los documentos e informes presentados por las partes, la correspondencia de la CCC, la correspondencia del mediador, los pedidos y los laudos emitidos por el único árbitro deberán permanecer en estricta confidencialidad y no deberán ser revelados a ningún tercero sin el consentimiento expreso por escrito de la otra parte.</p>
        </div>

        <div>
            <p class="subtitle2">7. TRATAMIENTO DE DATOS PERSONALES</p>
            <p class="paragraph">Al aceptar los presentes términos, el PROVEEDOR autoriza a GO GUAU el tratamiento de sus datos personales, de igual forma GO GUAU Se compromete a no revelar la información que se digita o transfiere a nuestra empresa, de acuerdo con las normas de la Ley 527 que reglamenta el Comercio Electrónico en Colombia y la Ley 1581 de 2012 sobre el uso de datos confidenciales. Con la presente Política de Tratamiento y Protección de Datos Personales.</p>
            <p class="paragraph">El responsable del tratamiento de los datos personales es el sr. Jose Luis Cerón, con domicilio en Popayán. El e-mail dispuesto para ese fin es: contacto@goguau.co</p>

            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong><strong>Tratamiento al cual serán sometidos los datos:</strong> </strong> </span><br><br>
                <span>Los datos y autorizaciones de tratamiento se recolectarán por medios electrónicos a través de la
                        página <a href="https://goguau.co">goguau.co</a> </span><br><br>   
                
                <span class="bullet">●</span><span class="margin"><strong><strong>Permisos de la aplicación web:</strong> </strong> </span><br><br>
                <span>En esta aplicación puede pedir algunos permisos que le permitan desarrollar acciones para crear
                    solicitudes de servicio, y productos. Los permisos solicitados serán los siguientes:</span><br><br>   
                
                <span class="bullet">●</span><span class="margin"><strong><strong>Información básica:</strong> </strong> </span><br><br>
                <span>Por defecto, esto incluye ciertos datos del usuario, tales como identificador, nombre, foto, correo
                    electrónico y su localidad.</span><br><br>   
                <span>Los datos personales pueden proporcionarse libremente por el usuario, o automáticamente cuando haga uso de la aplicación.</span><br><br>   
                <span>Los usuarios son responsables de todos los datos personales obtenidos de terceros, publicados o compartidos a través de esta aplicación y confirmar que tienen el consentimiento del tercero para proporcionar los datos.</span><br><br>   
                <span>Los datos recaudados, así como las autorizaciones de los usuarios registrados en la aplicación goguau.co, deberán contener la autorización expresa de dichos usuarios para que goguau.co pueda utilizarlos. Los registros y las autorizaciones serán almacenados en las bases de datos de goguau.co y permanecerán bajo su custodia en condiciones de idoneidad, confidencialidad y seguridad generalmente admitidas. Sólo el personal autorizado podrá acceder a estas bases de datos. Se observarán los protocolos de acceso y seguridad que se consideran estándar en estas actividades para evitar vulneración o manipulación de la información recopilada.</span><br><br>   
                <span>No obstante lo anterior, goguau.co podrá operar las bases de datos mediante un encargado del tratamiento de datos, en cuyo caso, hará saber a los titulares de la información que estas políticas se extenderán y, por ello, serán aplicables a tal encargado, de forma que el titular pueda ejercer los derechos que le confiere la ley, tanto frente a goguau.co como frente al encargado designado por ésta. La información recopilada se usará en la forma descrita enseguida.</span><br><br>   

                <span class="bullet">●</span><span class="margin"><strong><strong>Actualizaciones de los términos:</strong> </strong> </span><br><br>
                <span>Go Guau puede actualizar los presentes términos para reflejar las modificaciones que se generan en los servicios. Por lo tanto tanto, debe revisar estas páginas periódicamente, si renovamos de forma sustancial estos términos, le notificaremos con debida antelación. El uso continuado de los servicios después de dichos cambios constituye su aceptación.</span><br><br>  
                
                <span class="bullet">●</span><span class="margin"><strong><strong>Uso (finalidad) de la información personal recopilada en las bases de datos:</strong> </strong> </span><br><br>
                <span>La información personal que ha recopilado goguau.co desde el inicio de sus operaciones en el año
                    2020, y la que recopile en adelante, se usará para los siguientes fines:</span><br><br>   
                <span>Envío de información y documentos relacionados con los servicios que debe prestar la administración de goguau.co, esto es, la que deba remitirse a los usuarios en general hagan uso de sus servicios o productos.</span><br><br>   
                <span>Remisión de información, requerimientos y notificaciones a todos los empleados, proveedores, contratistas y colaboradores de goguau.co, que se encuentran registrados en nuestras bases de datos.</span><br><br>   
                <span>Envío de correos electrónico y/o demás mensajes de datos.</span><br><br>  
                
                <span>Remisión de ofertas, incentivos y promociones de los usuarios de goguau.co.</span><br><br>  
                <span>Análisis y segmentación de la información para elaborar estudios y estadísticas sobre preferencias de consumo.</span><br><br>  
                <span>Dar cumplimiento a exigencias legales y requerimientos de autoridades judiciales.</span><br><br>  
                <span><strong>Derechos que le asisten al titular de la información</strong> </span><br><br>  
                
                <span>De acuerdo con las normas constitucionales y legales, el titular de la información recopilada en nuestra base de datos cuenta con las siguientes prerrogativas y derechos, los cuales puede invocar o ejercer frente a goguau.co o frente al encargado del tratamiento de datos personal que obra por cuenta de goguau.co como responsable:</span><br><br>  
                <span>Acceder de manera gratuita a sus datos personales, así como conocerlos, rectificarlos, corregirlos y actualizarlos, siguiendo los procedimientos establecimientos más adelante.</span><br><br>  
                <span>Solicitar y obtener prueba de la autorización concedida para el tratamiento de sus datos personales, excepto en el caso en que ella se presuma por haberse usado mecanismos, como lo prevé en el artículo 10 del decreto 1377 de 2013.</span><br><br>  
                <span>Obtener información sobre el uso que se ha dado a su información personal.</span><br><br>  
                
                <span>Acudir ante las autoridades, especialmente ante la Superintendencia Delegada para la Protección de Datos Personales de las SIC, con el fin de solicitar y exigir al amparo de los derechos que le confieren las leyes.</span><br><br>  
                <span>Renovar, en cualquier momento, a autorización para el tratamiento de sus datos personales, modificarla o condicionarla. Así mismo, solicitar la supresión del dato, su modificación o aclaración, salvo que sea necesaria la información por razones legales o contractuales.</span><br><br>  

                
                <span class="bullet">●</span><span class="margin"><strong><strong>Procedimientos para que los titulares de la información que reposa en nuestra bases de datos, y la que se recopile en adelante:</strong> </strong> </span><br><br>
                <span>goguau.co establece los siguientes procedimientos:</span><br><br>   
                <span>1. El titular de la información (entendiéndose por él a sus causahabientes y apoderados) podrá solicitar en cualquier tiempo, dentro de los horarios de atención al público o de forma electrónica, información (consultas) sobre datos personales que se registran las bases de datos de Go Guau y sobre las autorizaciones concedidas.</span><br><br>   
                <span>2. Así mismo, podrá elevar peticiones o reclamaciones sobre aclaración, corrección, modificación, rectificación o supresión de datos; revocación o condicionamiento de autoridades para el tratamiento, acompañando los documentos o pruebas que pretendan hacer valer.</span><br><br>   
                <span>3. Para ejercer las prerrogativas a que se refieren los numerales anteriores, el titular deberá radicar petición escrita en las oficinas de administración Go Guau dirigida al ÁREA DE ADMINISTRACIÓN, identificándose plenamente, a fin de que Go Guau pueda corroborar que el peticionario es el titular de la información. En solicitud se debe precisar: a) Nombre completo y correcto del titular y/o su apoderado, si es el caso. b).Identificación del titular y de su representante, en el evento en que actúe a través de su apoderado. c) Dato o autorización que se requiere conocer, corregir, modificar, suprimir o revocar, con indicación clara y detallada de la forma en que se pide hacer la corrección o modificación. d) Domicilio o lugar donde recibirán respuesta.</span><br><br>   

                <span>4. El titular también puede remitir un correo a la siguiente dirección electrónica: contacto@goguau.co, dirigido al ÁREA DE INFORMACIÓN, con la información señalada en el numeral anterior. En este caso, Go Guau enviará la respuesta correspondiente a la misma dirección de correo de la cual procede la petición, siempre y cuando coincida con la registrada en la base de datos. No obstante, si lo considera, y con el único fin de establecer la identidad plena del peticionario y titular de la información, Go Guau podrá requerir esa información para que se identifique, antes de preceder a responderle.</span><br><br>   
                <span>5. La respuesta a las consultas a que se refiere el numeral 1°, será enviada por Go Guau en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo de la misma.</span><br><br>   
                <span>6. Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado, expresando los motivos de la demora y señalando la fecha que se atenderá su consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.</span><br><br>   
                <span>7. La respuesta a las reclamaciones a que se refiere el numeral 2°, se dará por Go Guau en un término máximo de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al interesado los motivos de la demora y al fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ochos (8) días hábiles siguientes al vencimiento del primer término.</span><br><br>                                                    
            </p>
        </div>

        <div>
            <p class="subtitle2">8. OTRAS DISPOSICIONES</p>

            <p class="paragraph">Las reclamaciones por infracción de derechos de autor deberán enviarse por escritor al correo {{email}}</p>

            <p class="paragraph padded">
                <span class="bullet">●</span><span class="margin"><strong><strong>Notificaciones:</strong> </strong> </span><br><br>
                <span>GO GUAU podrá notificar por medio de una ubicación general en su plataforma, mediante un correo electrónico enviado a su dirección electrónica o por comunicación escrita enviada a su dirección de residencia, según lo dispuesto en su Cuenta de usuario.</span><br><br>  
                
                <span class="bullet">●</span><span class="margin"><strong><strong>Disposiciones generales:</strong> </strong> </span><br><br>
                <span>No podrá ceder ni transferir estas Condiciones, en todo o en parte, sin el consentimiento previo y escrito de GO GUAU. Usted da su aprobación a GO GUAU para ceder o transferir estas condiciones,
                    en todo o en parte, incluido a: (i) una subsidiaria o un afiliado; (ii) un adquirente del capital, del negocio o de los activos de GO GUAU; o (iii) un sucesor por fusión.</span><br><br>  
                <span>Si cualquier disposición de estas Condiciones se considerara ilegal, nula o inexigible, ya sea en su totalidad o en parte, de conformidad con cualquier legislación, dicha disposición se considerará que no forma parte de estas Condiciones, aunque la legalidad, validez y exigibilidad del resto de las disposiciones no se verá afectada. En ese caso, las partes deberán reemplazar dicha disposición por una disposición legal, válida y exigible que tenga, en la medida de lo posible, un efecto similar al que tenía la disposición nula. Estas Condiciones constituyen el contrato íntegro y el entendimiento entre las partes en relación con el objeto y sustituye y reemplaza a todos los contratos o acuerdos anteriores o contemporáneos en relación con dicho objeto.</span><br><br> 
            </p>
        </div>
    </div>    
</div>
</template>

<script>
import termsData from '../../utils/PlansData'
export default {
    name: 'terms_and_conditions',
    data(){
        return{
            items: termsData.usersData,
            fields: [
                { key: 'concept', label: '', },
                { key: 'hrs_min', label: 'Compra mínima en sesiones', },
                { key: 'hrs_max', label: 'Compra máxima en sesiones', },
                { key: 'cost1', label: 'Costo sesión 1 mascota', },
                { key: 'cost2', label: 'Costo sesión 2 mascotas', },
                { key: 'cost3', label: 'Costo sesión 3 mascotas', },
                { key: 'expiration', label: 'Caducidad en días', },
            ],
            fieldsReschedule: [
                { key: 'package', label: 'Paquete', },
                { key: 'quantity', label: 'Nro. de cancelaciones con reprogramación', },               
            ],
            itemsReschedule: termsData.rescheduleQuantity,
            itemsRainReschedule: termsData.rescheduleRainQuantity,
            activePage: 1,
            line: '319 411 5792',
            email: 'contacto@goguau.co',

            itemsWalkingFeats: termsData.walkingFeats,
            fieldsWalkingFeats: [
               { key: 'concept', label: 'Concepto', },
               { key: 'description', label: 'Descripción', },  
            ],

            itemsTrainingFeats: termsData.trainingFeats,
            fieldsTrainingFeats: [
               { key: 'concept', label: 'Concepto', },
               { key: 'description', label: 'Descripción', },  
            ],

            itemsWalkerFees: termsData.walkerFees,
            fieldsWalkerFees: [
                { key: 'concept', label: '', },                                
                { key: 'cost1', label: 'Costo 1 mascota', },
                { key: 'cost2', label: 'Costo 2 mascotas', },
                { key: 'cost3', label: 'Costo 3 mascotas', },
                { key: 'cost4', label: 'Costo 4 mascotas', },
                { key: 'extrafee', label: 'Incremento por mascota', },
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            dismissCountDown: 4,
            dismissSecs: 4,
        }
    },
    watch: {
        $route: {
        immediate: true,
        handler (route) {
            if (route.query && route.query.page) {
            this.activePage = Number(route.query.page)
            }
        }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields.filter(f => f.sortable).map(f => {
            return { text: f.label, value: f.key };
            });
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods:{
        getBadge (concept) {
            switch (concept) {
                case 'Paseos': return 'info'                       
                case 'Entrenamiento': return 'info'                
                case 'Tarifas adicionales': return 'info'        
                case 'Adicionales por servicio': return 'info'        
            
                default: 'primary'
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },      
    }
}
</script>

<style scoped>

	.title{
		padding: 2rem 0;
	}

	.subtitle{
		font: bold 20px 'Arial';
		color: #1155cc;
		line-height: 16px;
	}
	.subtitle2{
		font: bold 18px 'Arial';
		color: #1155cc;
		line-height: 14px;
	}

	.padded{
		padding-left: 68px;
	}

	.paragraph{
		text-align: justify;
		font: 20px;
		color: #666666;
		line-height: 19px;
	}

	.bullet{
		font: 13px 'Arial';
		color: #666666;
		line-height: 16px;
	}

	.number{
		font: 20px;
		color: #666666;
		line-height: 16px;
	}

	.margin{
		margin-left: 16px;
	}

</style>
